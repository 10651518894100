import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "attributions",
  id: "actividades"
}
const _hoisted_2 = { class: "attributions__text" }
const _hoisted_3 = { class: "attributions__text--title" }
const _hoisted_4 = { class: "attributions__text--description" }
const _hoisted_5 = {
  href: "https://vuejs.org/",
  target: "_blank"
}
const _hoisted_6 = { class: "attributions__text--description" }
const _hoisted_7 = {
  href: "https://nodejs.org/es/",
  target: "_blank"
}
const _hoisted_8 = { class: "attributions__text--description" }
const _hoisted_9 = {
  href: "https://element-plus.org/",
  target: "_blank"
}
const _hoisted_10 = { class: "attributions__text--description" }
const _hoisted_11 = {
  href: "https://www.freepik.com/free-vector/white-abstract-background_11852424.htm",
  target: "_blank"
}
const _hoisted_12 = {
  href: "https://www.freepik.com/",
  target: "_blank"
}
const _hoisted_13 = { class: "attributions__text--description" }
const _hoisted_14 = {
  href: "https://unsplash.com/photos/zZdxxAEhOYg",
  target: "_blank"
}
const _hoisted_15 = {
  href: "https://unsplash.com/@borre?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText",
  target: "_blank"
}
const _hoisted_16 = {
  href: "https://unsplash.com/s/photos/coru%C3%B1a?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText",
  target: "_blank"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", _hoisted_3, _toDisplayString($setup.locale.attributions.title), 1),
      _createElementVNode("p", _hoisted_4, [
        _createElementVNode("a", _hoisted_5, _toDisplayString($setup.locale.attributions.project[0].name) + ":", 1),
        _createTextVNode(" " + _toDisplayString($setup.locale.attributions.project[0].contribution), 1)
      ]),
      _createElementVNode("p", _hoisted_6, [
        _createElementVNode("a", _hoisted_7, _toDisplayString($setup.locale.attributions.project[1].name) + ":", 1),
        _createTextVNode(" " + _toDisplayString($setup.locale.attributions.project[1].contribution), 1)
      ]),
      _createElementVNode("p", _hoisted_8, [
        _createElementVNode("a", _hoisted_9, _toDisplayString($setup.locale.attributions.project[2].name) + ":", 1),
        _createTextVNode(" " + _toDisplayString($setup.locale.attributions.project[2].contribution), 1)
      ]),
      _createElementVNode("p", _hoisted_10, [
        _createTextVNode(_toDisplayString($setup.locale.attributions.project[3].name) + ": ", 1),
        _createElementVNode("a", _hoisted_11, _toDisplayString($setup.locale.attributions.project[3].contribution[0]), 1),
        _createTextVNode(" " + _toDisplayString($setup.locale.attributions.project[3].contribution[1]) + " ", 1),
        _createElementVNode("a", _hoisted_12, _toDisplayString($setup.locale.attributions.project[3].contribution[2]), 1)
      ]),
      _createElementVNode("p", _hoisted_13, [
        _createTextVNode(_toDisplayString($setup.locale.attributions.project[4].name) + ": ", 1),
        _createElementVNode("a", _hoisted_14, _toDisplayString($setup.locale.attributions.project[4].contribution[0]), 1),
        _createTextVNode(" " + _toDisplayString($setup.locale.attributions.project[4].contribution[1]) + " ", 1),
        _createElementVNode("a", _hoisted_15, _toDisplayString($setup.locale.attributions.project[4].contribution[2]), 1),
        _createTextVNode(" " + _toDisplayString($setup.locale.attributions.project[4].contribution[3]) + " ", 1),
        _createElementVNode("a", _hoisted_16, _toDisplayString($setup.locale.attributions.project[4].contribution[4]), 1)
      ])
    ])
  ]))
}