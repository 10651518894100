import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_navigation_bar = _resolveComponent("navigation-bar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_custom_footer = _resolveComponent("custom-footer")!
  const _component_el_container = _resolveComponent("el-container")!
  const _component_ProvideLanguage = _resolveComponent("ProvideLanguage")!

  return (_openBlock(), _createBlock(_component_ProvideLanguage, null, {
    default: _withCtx(() => [
      _createVNode(_component_el_container, null, {
        default: _withCtx(() => [
          _createVNode(_component_navigation_bar),
          _createVNode(_component_router_view),
          _createVNode(_component_custom_footer)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}