
import { defineComponent } from 'vue'
import { ElMain } from 'element-plus'
import TrainingsList from '@/components/activity/TrainingsList.vue'
import { event } from 'vue-gtag'

export default defineComponent({
    name: 'Trainings',
    components: { ElMain, TrainingsList },
    beforeMount(){
        event('trainings', { method: 'Google' })
    },
})
