import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "contributions",
  id: "actividades"
}
const _hoisted_2 = { class: "contributions__text" }
const _hoisted_3 = { class: "contributions__text--title" }
const _hoisted_4 = { class: "contributions__text--subtitle" }
const _hoisted_5 = { class: "contributions__text--description" }
const _hoisted_6 = {
  href: "https://scholar.google.es/citations?user=E3EH-XEAAAAJ&hl=es&oi=ao",
  target: "_blank"
}
const _hoisted_7 = { class: "contributions__text--description" }
const _hoisted_8 = {
  href: "https://github.com/Cris-ti-na",
  target: "_blank"
}
const _hoisted_9 = { class: "contributions__text--description" }
const _hoisted_10 = {
  href: "https://github.com/Guillx",
  target: "_blank"
}
const _hoisted_11 = { class: "contributions__text--description" }
const _hoisted_12 = {
  href: "https://github.com/JorgeMayoral",
  target: "_blank"
}
const _hoisted_13 = { class: "contributions__text--description" }
const _hoisted_14 = {
  href: "https://github.com/RogelioBlancoP",
  target: "_blank"
}
const _hoisted_15 = { class: "contributions__text--description" }
const _hoisted_16 = {
  href: "https://github.com/patypocando",
  target: "_blank"
}
const _hoisted_17 = { class: "contributions__text--description" }
const _hoisted_18 = {
  href: "https://github.com/rubenmolina54",
  target: "_blank"
}
const _hoisted_19 = { class: "contributions__text--description" }
const _hoisted_20 = {
  href: "https://github.com/sabelacv",
  target: "_blank"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", _hoisted_3, _toDisplayString($setup.locale.contributions.title), 1),
      _createElementVNode("p", _hoisted_4, _toDisplayString($setup.locale.contributions.subtitle), 1),
      _createElementVNode("p", _hoisted_5, [
        _createElementVNode("a", _hoisted_6, _toDisplayString($setup.locale.contributions.person[0].name) + ":", 1),
        _createTextVNode(" " + _toDisplayString($setup.locale.contributions.person[0].contribution), 1)
      ]),
      _createElementVNode("p", _hoisted_7, [
        _createElementVNode("a", _hoisted_8, _toDisplayString($setup.locale.contributions.person[1].name) + ":", 1),
        _createTextVNode(" " + _toDisplayString($setup.locale.contributions.person[1].contribution), 1)
      ]),
      _createElementVNode("p", _hoisted_9, [
        _createElementVNode("a", _hoisted_10, _toDisplayString($setup.locale.contributions.person[2].name) + ":", 1),
        _createTextVNode(" " + _toDisplayString($setup.locale.contributions.person[2].contribution), 1)
      ]),
      _createElementVNode("p", _hoisted_11, [
        _createElementVNode("a", _hoisted_12, _toDisplayString($setup.locale.contributions.person[3].name) + ":", 1),
        _createTextVNode(" " + _toDisplayString($setup.locale.contributions.person[3].contribution), 1)
      ]),
      _createElementVNode("p", _hoisted_13, [
        _createElementVNode("a", _hoisted_14, _toDisplayString($setup.locale.contributions.person[4].name) + ":", 1),
        _createTextVNode(" " + _toDisplayString($setup.locale.contributions.person[4].contribution), 1)
      ]),
      _createElementVNode("p", _hoisted_15, [
        _createElementVNode("a", _hoisted_16, _toDisplayString($setup.locale.contributions.person[5].name) + ":", 1),
        _createTextVNode(" " + _toDisplayString($setup.locale.contributions.person[5].contribution), 1)
      ]),
      _createElementVNode("p", _hoisted_17, [
        _createElementVNode("a", _hoisted_18, _toDisplayString($setup.locale.contributions.person[6].name) + ":", 1),
        _createTextVNode(" " + _toDisplayString($setup.locale.contributions.person[6].contribution), 1)
      ]),
      _createElementVNode("p", _hoisted_19, [
        _createElementVNode("a", _hoisted_20, _toDisplayString($setup.locale.contributions.person[7].name) + ":", 1),
        _createTextVNode(" " + _toDisplayString($setup.locale.contributions.person[7].contribution), 1)
      ])
    ])
  ]))
}