import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_hero = _resolveComponent("hero")!
  const _component_about_us = _resolveComponent("about-us")!
  const _component_objectives_carousel = _resolveComponent("objectives-carousel")!
  const _component_activities = _resolveComponent("activities")!
  const _component_el_main = _resolveComponent("el-main")!

  return (_openBlock(), _createBlock(_component_el_main, null, {
    default: _withCtx(() => [
      _createVNode(_component_hero),
      _createVNode(_component_about_us),
      _createVNode(_component_objectives_carousel),
      _createVNode(_component_activities)
    ]),
    _: 1
  }))
}